(function () {
    if (document.querySelector("#_server_data_global")) {
        try {
            const serverDataGlobal = JSON.parse(
                (<HTMLScriptElement>document.querySelector("#_server_data_global")).text,
            );
            __webpack_public_path__ = `${serverDataGlobal.static_url}js/`;
        } catch (error) {
            console.error("Error parsing #_server_data_global", error);
        }
    }
})();
